import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AboutPage from "../../components/page-about"

export default () => {
  const gatsbyData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          lang_configs {
            fa {
              menu {
                prefix
                items {
                  label
                  path
                  external
                  no_pref
                }
              }
            }
          }
        }
      }
    }
  `)
  return (
    <AboutPage lang_config={gatsbyData.site.siteMetadata.lang_configs.fa} />
  )
}
